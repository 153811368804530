// listn to (all events on )inputs of form and return true if all filled
export function listenToInput(form) {
  const inputs = form.querySelectorAll('input');
  const btn = form.querySelector('button');
  var allFilled;
  inputs.forEach((each) => {
    each.addEventListener('input', isFilled);
  });
  function isFilled() {
    allFilled = true;
    inputs.forEach((each) => {
      if (each.value.trim() === '') {
        allFilled = false;
      }
    });
    if (!allFilled) {
      btn.classList = 'btn-off';
    } else {
      btn.classList = '';
    }
  }
}

// show error message on top of form
export function showErrorForm(form, message) {
  if (form.querySelector('.error-top') !== null) {
    return;
  }
  const error = document.createElement('p');
  error.classList = 'error-top';
  error.innerText = message;
  form.insertAdjacentElement('afterbegin', error);
}

export function deleteErrorForm(form) {
  var elem = form.querySelector('.error-top');
  if (elem !== null) {
    form.removeChild(elem);
  }
}

// Show input error message
export function showError(input, message) {
  const smallEl = input.parentElement.querySelector('small');
  input.classList.add('red');
  smallEl.classList.add('red');
  smallEl.innerText = message;
}

// Show success outline
export function showSuccess(input) {
  const smallEl = input.parentElement.querySelector('small');
  input.classList.remove('red');
  smallEl.classList.remove('red');
}

// Check required fields
export function checkRequired(input, message) {
  if (input.value.trim() === '') {
    showError(input, message);
    return false;
  } else {
    showSuccess(input);
    return true;
  }
}

// Check input length
export function checkLength(input, min, max, nameObject = 'Das Passwort') {
  if (input.value.trim().length < min) {
    showError(
      input,
      `${nameObject} muss aus mindestens ${min} Zeichen bestehen`
    );
    return false;
  } else if (input.value.length > max) {
    showError(
      input,
      `${nameObject} darf nicht aus mehr wie ${max} Zeichen bestehen`
    );
    return false;
  } else {
    showSuccess(input);
    return true;
  }
}

// Check passwords match
export function checkPasswordsMatch(input1, input2) {
  if (input1.value !== input2.value) {
    showError(input2, 'Die Passwörter stimmen nicht überein');
    return false;
  } else {
    showSuccess(input2);
    return true;
  }
}

export function readLinebreaks(text) {
  return text.replace(/\r?\n/g, '<br />');
}

export function getPostId() {
  var url = window.location.href;
  return url.substring(url.lastIndexOf('/') + 1);
}
