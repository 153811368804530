import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';

export default class Dropdown extends Standard {
  constructor(element, number) {
    super();
    this.container = element;
    if (!super.exist()) return;
    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.ul = super.set('ul');
    this.button = super.set('.dropdown-btn');
    this.choice = super.set('.choice');
    this.position = this.container.parentNode.parentNode.dataset.position;
    this.choice.innerText = this.position;
    this.number = number;
    this.endpoint = super.setEndpoint('gallerie_api/change_position');
    this.init();
  }

  init() {
    this.read();
    this.liElements = super.set('li', true);
    this.events();
  }
  read() {
    const areasArray = Array.from(Array(this.number), (_, i) => i + 1);
    areasArray.forEach((each, index) => {
      const text = ` <li> 
          ${each}</li>`;
      this.ul.innerHTML += text;
    });
  }
  toggle(e) {
    e.preventDefault();
    var dropdowns = document.querySelectorAll('.dropdown-element');
    dropdowns.forEach((each) => {
      if (each.querySelector('button') !== this.button) {
        each.classList.remove('active');
      }
    });

    this.container.classList.toggle('active');
    function outsideClick(e) {
      if (!e.target.matches('.dropdown-btn')) {
        var dropdowns = document.querySelectorAll('.dropdown-element');
        dropdowns.forEach((each) => {
          if (each.classList.contains('active')) {
            each.classList.remove('active');
          }
        });
      }
    }

    document.addEventListener('click', (e) => outsideClick(e));
  }
  async set(text) {
    this.hl.toggle();
    const reqData = {
      old_position: this.choice.innerText,
      new_position: text,
    };
    console.log(reqData);
    this.choice.innerText = text;
    const res = await this.http.put(this.endpoint, reqData);
    console.log(res);
    await this.hl.wait();
    location.reload();
  }
  events() {
    this.button.addEventListener('click', (e) => this.toggle(e));
    this.liElements.forEach((each) => {
      each.addEventListener('click', () => this.set(each.innerText));
    });
  }
}
