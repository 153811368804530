/* 
      <div class="admin-text-box">
        <div class="content">
          <div class="close"><i class="far fa-times-circle"></i></div>
          <p class="pt-4">
            Wähle ein Beschreibung zum Text.
          </p>
          <div class="input-container">
            <input type="text" />
          </div>
          <div class="btn_container">
            <button>
              Erstellen
            </button>
          </div>
        </div>
      </div>

  if (document.querySelector('#BoxMessage') !== null) {
  const boxMessageBackend = new BoxMessage();
  setTimeout(() => boxMessageBackend.toggle(), 1000);
}
<?php } ?>
*/
import Standard from '../../js/Libraries/Standard';
//  Box from top
export default class AdminTextBox extends Standard {
  constructor() {
    super();
    this.create();
  }

  create() {
    if (document.querySelector('admin-text-box') === null) {
      const box = document.createElement('div');
      box.className = 'admin-text-box';
      box.innerHTML = `
        <div class="content">
            <div class="close"><i class="far fa-times-circle"></i></div>
                <p class="pt-4">
                Wähle ein Beschreibung zum Text.
                </p>
            <div class="input-container">
                <input class="data-text" type="text" />
            </div>
            <p class="pt-1">
            Wähle eine Größe:(zwischen 200-500)
            </p>
        <div class="input-container">
            <input class="data-height" type="text" /> px
        </div>
            <div class="btn_container">
                <button>Erstellen</button>
            </div>
        </div>`;

      document.body.insertAdjacentElement('afterbegin', box);
      this.box = document.querySelector('.admin-text-box');
      this.input = this.box.querySelector('input.data-text');
      this.height = this.box.querySelector('input.data-height');
      this.closeBtn = this.box.querySelector('.close');
      this.confirmBtn = this.box.querySelector('button');
    }
  }
  toggle(text = '', height = 300) {
    this.box.classList.add('active');
    this.input.value = text;
    this.height.value = height;
    // this.box.querySelector('p').innerText = msg;
    function closeBox(self, resolve) {
      self.box.classList.remove('active');
      resolve(false);
    }
    function outsideClick(e, self, resolve) {
      if (e.target == self.box) {
        self.box.classList.remove('active');
        resolve(false);
      }
    }

    function confirmFct(self, resolve) {
      self.box.classList.remove('active');
      resolve([self.input.value.trim(), self.height.value.trim()]);
    }
    return new Promise((resolve, reject) => {
      this.closeBtn.addEventListener('click', () => closeBox(this, resolve));
      document.addEventListener('click', (e) => outsideClick(e, this, resolve));
      this.confirmBtn.addEventListener('click', (e) =>
        confirmFct(this, resolve)
      );
    });
  }
}
