// store images when same root
class ImageSlidesOneEl {
  constructor(root, id) {
    this.id = id;
    this.images = root.querySelectorAll('img');
    this.current = 0; //active element; example lightbox
  }
  nextImage() {
    if (this.current + 1 >= this.images.length) {
      this.current = 0;
    } else {
      this.current += 1;
    }
    return this.images[this.current];
  }

  prevImage() {
    if (this.current - 1 < 0) {
      this.current = this.images.length - 1;
    } else {
      this.current -= 1;
    }
    return this.images[this.current];
  }
}
// takes multiple roots and make for each root a lightbox slideshow
export default class ImageSlidesPopUp {
  constructor(id = '') {
    if (id === '') {
      this.imageSlidesPopUpEls = document.querySelectorAll('.ImageSlidesPopUp');
      if (this.imageSlidesPopUpEls === null) return;
      this.imageSlides = [];
      this.imageSlidesPopUpEls.forEach((value, index) => {
        const one = new ImageSlidesOneEl(value, index);
        this.imageSlides.push(one);
      });
    } else {
      this.imageSlidesPopUpOneEl = document.querySelector(id);
      if (this.imageSlidesPopUpOneEl === null) return;
      this.imageSlides = [new ImageSlidesOneEl(this.imageSlidesPopUpOneEl, 0)];
    }

    if (this.imageSlides.length === 0) {
      return;
    }
    this.current = 0;
    this.lightboxEl = this.createLightBoxEl();
    this.closeBtn = this.lightboxEl.querySelector('.close');
    this.nextBtn = this.lightboxEl.querySelector('.next');
    this.prevBtn = this.lightboxEl.querySelector('.prev');
    this.currentImg = this.lightboxEl.querySelector('img');
    this.altText = this.lightboxEl.querySelector('.alt-text');
    this.contentContainer = this.lightboxEl.querySelector('.content-container');
    this.setEventListenerPopUp();
    this.lightboxEvents();
    this.keydownEvents();
  }

  createLightBoxEl() {
    if (document.querySelector('#lightbox') !== null) {
      return document.querySelector('#lightbox');
    }
    const lightbox = document.createElement('div');
    lightbox.id = 'lightbox';
    // lightbox.classList = 'active';
    lightbox.innerHTML = `
               <img src="" alt="Portfolio eines Fotografen" />
              <div class="close">
                <i class="far fa-times-circle"></i>
              </div>
              <div class="prev">
                <i class="fas fa-arrow-left"></i>
              </div>
              <div class="next">
                <i class="fas fa-arrow-right"></i>
              </div>
            <div class="content-container">
              <p class="alt-text"></p>
            </div>
          

    `;
    document.body.appendChild(lightbox);
    return document.querySelector('#lightbox');
  }

  popUpImage(activeImageSlide, image) {
    this.current = activeImageSlide.id;
    this.lightboxEl.classList.add('active');
    this.currentImg.src = image.src;
    this.currentImg.img = image.alt;
    if (image.dataset.text !== '' && image.dataset.text !== undefined) {
      this.contentContainer.style.opacity = 1;
      this.altText.innerText = image.dataset.text;
    } else {
      this.contentContainer.style.opacity = 0;
    }

    activeImageSlide.images.forEach((value, index) => {
      if (value.src === image.src) {
        activeImageSlide.current = index;
        return;
      }
    });
  }

  setEventListenerPopUp() {
    this.imageSlides.forEach((each) => {
      each.images.forEach((image) => {
        image.addEventListener('click', (e) => {
          this.popUpImage(each, image);
        });
      });
    });
  }

  displayImage(which = 'next') {
    let newImage = null;
    if (which === 'next') {
      newImage = this.imageSlides[this.current].nextImage();
    } else if (which === 'prev') {
      newImage = this.imageSlides[this.current].prevImage();
    }
    this.currentImg.src = newImage.src;
    this.currentImg.img = newImage.alt;
    if (newImage.dataset.text !== '' && newImage.dataset.text !== undefined) {
      this.contentContainer.style.opacity = 1;
      this.altText.innerText = newImage.dataset.text;
    } else {
      this.contentContainer.style.opacity = 0;
    }
  }
  lightboxEvents() {
    this.closeBtn.addEventListener('click', () =>
      this.lightboxEl.classList.remove('active')
    );
    this.nextBtn.addEventListener('click', () => this.displayImage('next'));
    this.prevBtn.addEventListener('click', () => this.displayImage('prev'));
    this.lightboxEl.addEventListener('click', (e) => {
      if (e.target === this.lightboxEl)
        this.lightboxEl.classList.remove('active');
    });
  }

  keydownEvents() {
    document.addEventListener('keydown', (e) => {
      if (!this.lightboxEl.classList.contains('active')) {
        return;
      }
      if (e.keyCode == '39') {
        this.displayImage('next');
      }
      if (e.keyCode == '37') {
        this.displayImage('prev');
      }
    });
  }
}
