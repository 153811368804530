import KontaktFormular from './Home/Kontakt.js';
import Video from './Home/Video.js';
import ReadGallerie from './Home/ReadGallerie.js';
import ReadAusstellungen from './Home/ReadAusstellungen.js';
import './Gadgets/main';
import './Admin/main';
new Video('#StartVideo');

new KontaktFormular('#KontaktFormular');

new ReadAusstellungen('#read_ausstellungen');
new ReadGallerie('#read_gallerie');
const go2PresseBtn = document.querySelector('#go2PresseBtn');

if (go2PresseBtn !== null) {
  go2PresseBtn.addEventListener('click', () => {
    window.location.href = document.querySelector('base').href + 'presse';
  });
}
