import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';
import jump from 'jump.js';
import { readLinebreaks, checkValidEmail } from '../../js/Libraries/Form';

export default class KontaktFormular extends Standard {
  constructor(id) {
    super(id);
    return;
    if (!super.exist()) return;
    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.endpoint = super.setEndpoint('pages_api/sent_email');
    this.data = {};
    this.sentBtn = super.set('#sentBtn');
    this.errorTop = super.set('#errorTop');
    this.errorEmail = super.set('#errorEmail');
    this.errorLaw = super.set('#errorLaw');
    this.textInputs = super.set('input[type="text"]', true);
    this.message = super.set('textarea');
    this.email = super.set('#email');
    this.law = super.set('#checkbox-law');
    this.events();
  }
  checkEmail() {
    // check valid email
    const email = this.email.value.trim();
    if (!checkValidEmail(email)) {
      this.email.classList.add('error');
      this.errorEmail.classList.add('error');
      this.data['email'] = false;
    } else {
      this.email.classList.remove('error');
      this.errorEmail.classList.remove('error');
      this.data['email'] = email;
    }
  }
  setSuccess() {
    this.textInputs.forEach((each) => {
      each.value = '';
    });
    this.message.value = '';
    this.law.checked = false;
  }
  async sent() {
    this.textInputs.forEach((each) => {
      if (each.value.trim() === '') {
        each.classList.add('error');
        this.data[each.name] = false;
      } else {
        each.classList.remove('error');
        this.data[each.name] = each.value.trim();
      }
    });

    const msg = readLinebreaks(this.message.value);
    if (msg === '') {
      this.message.classList.add('error');
      this.data[this.message.name] = false;
    } else {
      this.message.classList.remove('error');
      this.data[this.message.name] = msg;
    }

    this.checkEmail();

    if (super.contains(this.data, false)) {
      jump('#KontaktFormular', {
        duration: (distance) => Math.abs(distance) * 4,
      });
      this.errorTop.classList.add('error');
      return;
    }

    this.errorTop.classList.remove('error');

    if (!this.law.checked) {
      this.errorLaw.classList.add('error');
      return;
    }
    this.errorLaw.classList.remove('error');

    this.hl.toggle();
    console.log(this.data);
    const res = await this.http.post(this.endpoint, this.data);
    console.log(res);
    await this.hl.wait();
    if (res.status === 200) {
      this.setSuccess();
      this.hl.message(
        'Vielen Dank! Ihre Nachricht wurde versendet. Ich werde mich so schnell wie möglich bei Ihnen melden. '
      );
      return this.hl.toggle();
    } else {
      this.hl.message('Es gibt zurzeit leider Probleme mit unserem Server. Versuchen Sie es später bitte noch einmal.');
      return this.hl.toggle();
    }
  }
  events() {
    this.sentBtn.addEventListener('click', () => this.sent());
  }
}
