import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Standard from '../../js/Libraries/Standard';
import Handlers from '../../js/Libraries/Handlers';
import ImageSlidesPopUp from '../../js/Libraries/ImageSlidesPopUp';

export default class ReadGallerie extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.row = super.set('#all_images_gallerie_container');
    this.endpointRead = super.setEndpoint('pages_api/get_images');
    this.init();
  }

  async init() {
    this.hl.toggle();
    await this.read();
    this.row.classList.add('ImageSlidesPopUp');
    new ImageSlidesPopUp();
    return this.hl.toggle();
  }

  async read() {
    const res = await this.http.get(this.endpointRead);
    console.log(res);
    if (res.status == 200) {
      const length = res.result.length;
      for (let i = 1; i <= length; i++) {
        let obj = res.result.find((x) => x.position == i);
        this.createNewElement(obj);
      }
    } else {
      console.log('error');
    }
  }

  createNewElement(data) {
    const el = document.createElement('div');
    el.classList = 'img_container col-md-6 col-lg-4';
    el.innerHTML = this.component(data);
    this.row.insertBefore(el, this.addElement);
  }
  component(data) {
    return `<img src="${data.path}" alt="${data.title}" data-text="${data.title}" style="height: ${+data.height}px;"/>`;
  }
}
