import * as checks from '../../js/Libraries/checks-form';
import Handlers from '../../js/Libraries/Handlers';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Standard from '../../js/Libraries/Standard';

export default class Login extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.userName = super.set('#userName');
    this.userPassword = super.set('#userPassword');
    this.btn = super.set('button');
    this.endpoint = super.setEndpoint('users_api/login');
    // load packages
    this.hl = new Handlers();
    this.http = new ZachiHttp();

    //
    checks.listenToInput(this.container);
    this.events();
  }

  async submit(e) {
    e.preventDefault();
    if (this.btn.classList.contains('btn-off')) {
      return;
    }
    this.hl.toggle();

    const reqData = {
      name: this.userName.value.trim(),
      password: this.userPassword.value.trim(),
    };

    const res = await this.http.post(this.endpoint, reqData);
    console.log(res);
    await this.hl.wait();

    if (res.status !== 200) {
      checks.showErrorForm(
        this.container,
        `Der Nutzername und das Passwort stimmen nicht mit unseren Unterlagen überein. Bitte überprüfe deine Angaben und versuche es erneut.`
      );
      this.userPassword.value = '';
      this.userName.focus();
      this.btn.classList = 'btn-off';
      return this.hl.toggle();
    } else if (res.status === 200) {
      super.redirect(res.result.redirect);
    }
  }

  events() {
    this.container.addEventListener('submit', (e) => this.submit(e));
  }
}
