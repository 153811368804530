import Bound from 'bounds.js';

const boundary = Bound({
  margins: {
    bottom: 400,
    top: 400,
  },
});

const testEl = document.querySelector('.ausstellungen_home');
const lazyLoadImages = document.querySelectorAll('.lazy-load-image');
const lazyLoadImagesFct = (image) => {
  return () => {
    image.src = image.dataset.src;
    boundary.unWatch(image);
  };
};

lazyLoadImages.forEach((image) => {
  boundary.watch(image, lazyLoadImagesFct(image));
});
