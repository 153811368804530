import Standard from '../../js/Libraries/Standard';

import Handlers from '../../js/Libraries/Handlers';

export default class PlayVideo extends Standard {
  constructor(id) {
    // sicher gehen dass toggle immer weg geht....
    super(id);
    if (!super.exist()) return;
    this.startTime = new Date();
    this.backUpTime = 6; //in sec
    this.hl = new Handlers();
    this.hl.toggle();
    this.video = super.set('video');
    this.events();
    this.backUp();
  }

  timeDiffinSec() {
    endTime = new Date();
    var timeDiff = endTime - this.startTime; //in ms
    // strip the ms
    timeDiff /= 1000;
    return Math.round(timeDiff);
  }

  async backUp() {
    await this.hl.wait(this.backUpTime * 1000);
    if (this.video.paused) {
      console.log('toggle weg nach backupTime');
      this.hl.removeToggle();
    }
  }
  start() {
    this.hl.removeToggle();
    this.video.play();
    this.video.loop = true;
  }

  events() {
    this.video.addEventListener('canplaythrough', (e) => {
      if (this.video.paused) {
        console.log('played in canplaythrough');
        this.start();
      }
    });
    this.video.addEventListener('loadeddata', () => {
      if (this.video.readyState === 4) {
        if (this.video.paused) {
          console.log('played in loadeddata');
          this.hl.removeToggle();
          this.video.play();
          this.video.loop = true;
        }
      }
    });
  }
}
