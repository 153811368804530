export default class Handlers {
  constructor() {
    this.create();
    this.loader = document.querySelector('.loader');
    this.overlay = document.querySelector('.loader-overlay');

    // MessageBox gleiches prinzip wie in BoxMessage

    this.createMessage();

    this.box = document.querySelector('#MessageInHandler');
    this.btn = this.box.querySelector('.close');
  }
  create() {
    if (document.querySelector('.loader') === null) {
      const loader = document.createElement('div');
      loader.innerHTML = '<span></span><span></span><span></span>';
      loader.classList = 'loader';
      document.body.insertAdjacentElement('afterbegin', loader);
      const loaderOverlay = document.createElement('div');
      loaderOverlay.classList = 'loader-overlay';

      document.body.insertAdjacentElement('afterbegin', loaderOverlay);
    }
  }

  createMessage() {
    if (document.querySelector('#MessageInHandler') === null) {
      const box = document.createElement('div');
      box.id = 'MessageInHandler';
      box.innerHTML = `
    <div class="content">
      <div class="close"><i class="far fa-times-circle"></i></div>
      <p class="pt-4">
          Silke Gerbershagen
      </p>
     <div class="btn_container">
        <button class="transparent">Zurück</button>  <button id="confirmBtn">Bestätigen</button> 
     </div>
    </div>`;

      document.body.insertAdjacentElement('afterbegin', box);
    }
  }
  toggle() {
    this.loader.classList.toggle('active');
    const overlay = document.querySelector('.loader-overlay');
    this.overlay.classList.toggle('active');
  }
  removeToggle() {
    this.loader.classList.remove('active');
    const overlay = document.querySelector('.loader-overlay');
    this.overlay.classList.remove('active');
  }
  resetForm(form) {
    form.reset();
  }
  message(msg = 'Nachricht von Handler', confirm = false) {
    this.box.classList.add('active');
    this.box.querySelector('p').innerText = msg;
    function closeBox(self, resolve = null) {
      self.box.classList.remove('active');
      if (resolve !== null) {
        resolve(false);
      }
    }
    function outsideClick(e, self, resolve = null) {
      if (e.target == self.box) {
        self.box.classList.remove('active');
        if (resolve !== null) {
          resolve(false);
        }
      }
    }

    function confirmFct(self, resolve) {
      self.box.classList.remove('active');
      resolve(true);
    }
    function declineFct(self, resolve) {
      self.box.classList.remove('active');
      resolve(false);
    }
    if (confirm === false) {
      this.btn.addEventListener('click', () => closeBox(this));
      document.addEventListener('click', (e) => outsideClick(e, this));
    }

    if (confirm) {
      return new Promise((resolve, reject) => {
        this.box.querySelector('.btn_container').style.display = 'flex';
        this.box
          .querySelector('.transparent')
          .addEventListener('click', () => declineFct(this, resolve));
        this.box
          .querySelector('#confirmBtn')
          .addEventListener('click', () => confirmFct(this, resolve));
        this.btn.addEventListener('click', () => closeBox(this, resolve));
        document.addEventListener('click', (e) =>
          outsideClick(e, this, resolve)
        );
      });
    }
  }
  wait(time = 2000) {
    return new Promise((resolve, reject) => {
      if (false) {
        reject('Looser');
      }
      setTimeout(() => {
        resolve();
      }, time);
    });
  }
}

export function createDisableOverlay() {
  const disable = document.createElement('div');
  disable.classList = 'disable-overlay';
  document.body.insertAdjacentElement('afterbegin', disable);
}

export function toggleDisableOverlay() {
  const disable = document.querySelector('.disable-overlay');
  disable.classList.toggle('active');
}
