export function readLinebreaks(text) {
  return text.replace(/\r?\n/g, '<br />');
}

export function read2Textarea(str) {
  return str.replace(/(<br>|<\/br>|<br \/>)/gim, '\n');
}

export function checkValidEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email.trim()) && email.trim() !== '') {
    return true;
  } else {
    return false;
  }
}
// valid https adress
export function validURL(str) {
  var pattern = new RegExp(
    '^(https:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

// images
export function validateSize(size, boundary = 1000000) {
  if (size > boundary) {
    return false;
  } else {
    return true;
  }
}

export function validateFormat(
  format,
  validFormat = ['image/jpeg', 'image/jpg', 'image/png']
) {
  let bool = false;
  validFormat.forEach((each) => {
    if (each === format) {
      bool = true;
    }
  });
  return bool;
}

export function replaceUmlaute(str) {
  const umlautMap = {
    Ü: 'UE',
    Ä: 'AE',
    Ö: 'OE',
    ü: 'ue',
    ä: 'ae',
    ö: 'oe',
    ß: 'ss',
  };
  return str.replace(/ä|ö|ü|ß/gi, function (matched) {
    return umlautMap[matched];
  });
}

export function insertUmlaute(str) {
  var umlautMap = {
    AE: 'Ä',
    OE: 'Ö',
    UE: 'Ü',
    ae: 'ä',
    oe: 'ö',
    ue: 'ü',
  };
  return str.replace(/ae|oe|aa|ue/gi, function (matched) {
    return umlautMap[matched];
  });
}
