import './extension';
import ImageSlidesPopUp from './ImageSlidesPopUp.js';

new ImageSlidesPopUp();

// import BoxMessage from './BoxMessage';
// if (document.querySelector('#BoxMessage') !== null) {
//   const boxMessageBackend = new BoxMessage();
//   setTimeout(() => boxMessageBackend.toggle(), 1000);
// }
