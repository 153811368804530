import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Standard from '../../js/Libraries/Standard';
import ImageSlidesPopUp from '../../js/Libraries/ImageSlidesPopUp';

export default class ReadAusstellungen extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.http = new ZachiHttp();
    this.row = super.set('#read_ausstellungen_container');
    this.endpointRead = super.setEndpoint('pages_api/get_items');
    this.init();
  }

  async init() {
    await this.read();
    new ImageSlidesPopUp();
  }

  async read() {
    const res = await this.http.get(this.endpointRead);
    console.log(res);
    if (res.status == 200) {
      res.result.forEach((each) => {
        this.createNewElement(each);
      });
    } else {
      console.log('error');
    }
  }

  createNewElement(data) {
    const el = document.createElement('div');
    el.classList = 'one-ausstellung';
    el.innerHTML = this.component(data);
    this.row.insertBefore(el, this.addElement);
  }
  component(item) {
    return `      
    <div class="content-one">
        <span class="termin">${item.date}</span>
        <span class="event">${item.title}</span>
    </div>`;
  }
}
