export default class Standard {
  constructor(id = '') {
    if (id !== '') {
      this.container = document.querySelector(id);
    }
  }
  set(selector, all = false) {
    if (all === true) {
      return this.container.querySelectorAll(selector);
    } else {
      return this.container.querySelector(selector);
    }
  }
  exist() {
    if (this.container !== null) {
      return true;
    } else {
      return false;
    }
  }

  getEndpoint() {
    return this.container.getAttribute('data-endpoint');
  }
  // ohen / mpüsst gehen
  setEndpoint(path) {
    return document.querySelector('base').href + path;
  }
  // hier ohne /
  redirect(path, newTab = false) {
    if (newTab === true) {
      window.open(path, '_blank');
    }
    window.location.href = path;
  }
  getBackend() {
    const el = document.querySelector('#backendData');
    if (el === null) {
      return false;
    } else {
      try {
        return JSON.parse(el.value);
      } catch (e) {
        return false;
      }
    }
  }

  contains(data, value) {
    let bool = false;
    data.forEach((each) => {
      if (each === value) {
        bool = true;
      }
    });
    return bool;
  }
}
