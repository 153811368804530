import Handlers from '../../js/Libraries/Handlers';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Standard from '../../js/Libraries/Standard';
import { readLinebreaks, read2Textarea } from '../../js/Libraries/Form';
class Item {
  constructor(id, title, content) {
    this.id = id;
    this.title = title;
    this.content = readLinebreaks(content);
  }
}

class ItemCtrl extends Standard {
  constructor() {
    super();
    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.items = [];
    this.currentItem = null;
    this.endpointRead = super.setEndpoint('ausstellung_api/get_items');
    this.endpointCreate = super.setEndpoint('ausstellung_api/new_item');
    this.endpointUpdate = super.setEndpoint('ausstellung_api/update_item');
    this.endpointDelete = super.setEndpoint('ausstellung_api/delete_item');
  }

  async init() {
    const res = await this.http.get(this.endpointRead);
    console.log(res);
    if (res.status === 200) {
      res.result.forEach((each) => {
        let newItem = new Item(each.id, each.date, each.title);
        this.items.push(newItem);
      });
    } else {
      location.reload();
    }
  }
  async addItem(title, content) {
    let ID;
    // Create ID
    const reqData = {
      date: title,
      title: content,
    };
    const res = await this.http.post(this.endpointCreate, reqData);
    console.log(res);
    if (res.status === 200) {
      ID = res.id;
      // Create new item
      let newItem = new Item(ID, title, content);
      this.items.push(newItem);
      return newItem;
    } else {
      location.reload();
    }
  }
  async deleteItem(id) {
    const res = await this.http.delete(this.endpointDelete + '?id=' + id);
    console.log(res);
    if (res.status === 200) {
      const ids = this.items.map(function (item) {
        return item.id;
      });
      const index = ids.indexOf(id);
      this.items.splice(index, 1);
    } else {
      location.reload();
    }
  }
  async updateItem(input) {
    const reqData = {
      id: this.currentItem.id,
      date: input.title,
      title: input.content,
    };
    const res = await this.http.put(this.endpointUpdate, reqData);
    console.log(res);
    if (res.status === 200) {
      let found = null;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === this.currentItem.id) {
          this.items[i].title = input.title;
          this.items[i].content = readLinebreaks(input.content);
          found = this.items[i];
        }
      }
      return found;
    } else {
      location.reload();
    }
  }
  getItemById(id) {
    let found = null;
    this.items.forEach(function (item) {
      if (item.id == id) {
        found = item;
      }
    });

    return found;
  }
  setCurrentItem(item) {
    this.currentItem = item;
  }
  getCurrentItem(item) {
    return this.currentItem;
  }
  getItems() {
    let array = [];
    this.items.forEach((each) => {
      const data = {
        id: each.id,
        title: each.title,
        content: each.content,
      };
      array.push(data);
    });
    return array;
  }
}
class UICtrl {
  constructor(id, one = false) {
    this.container = document.querySelector(id);
    this.UISelectors = {
      items: '.items',
      one_item: '.one_item',
      addBtn: '.addBtn',
      updateBtn: '.updateBtn',
      deleteBtn: '.deleteBtn',
      title: '.itemInputTitle',
      content: '.itemInputContent',
    };
    this.one = one;
    if (one) {
      this.UISelectors.content = '.itemInputTitle';
    }
  }

  init(items) {
    items.forEach((each) => {
      this.addItem(each);
    });
  }
  getSelectors() {
    return this.UISelectors;
  }
  clearInput() {
    this.container.querySelector(this.UISelectors.title).value = '';
    this.container.querySelector(this.UISelectors.content).value = '';
  }
  getItemInput() {
    return {
      title: this.container.querySelector(this.UISelectors.title).value,
      content: this.container.querySelector(this.UISelectors.content).value,
    };
  }
  addItem(item) {
    const div = document.createElement('div');
    div.className = 'one_item';
    div.id = `item-${item.id}`;
    div.innerHTML = this.component(item);
    // Insert item
    this.container.querySelector(this.UISelectors.items).insertAdjacentElement('beforeend', div);
  }
  addItemToForm(item) {
    this.container.querySelector(this.UISelectors.title).value = item.title;
    this.container.querySelector(this.UISelectors.content).value = read2Textarea(item.content);
    this.showEditState();
  }
  updateListItem(item) {
    let listItems = this.container.querySelectorAll(this.UISelectors.one_item);
    listItems = Array.from(listItems);
    listItems.forEach((listItem) => {
      const itemID = listItem.getAttribute('id');
      if (itemID === `item-${item.id}`) {
        this.container.querySelector(`#${itemID}`).innerHTML = this.component(item);
      }
    });
  }

  component(item) {
    return `
      <div class="titel">
          <h3>Bearbeiten</h3>
          <i class="edit-item fas fa-pencil-alt"></i>
      </div>
      <div class="one-ausstellung">
          <div class="content-one">
            <span class="termin">${item.title}</span>
            <span class="event">${item.content}</span>
          </div>
        </div>`;
  }
  deleteItem(id) {
    const itemID = `#item-${id}`;
    const item = this.container.querySelector(itemID);
    item.remove();
  }
  showEditState() {
    this.container.querySelector(this.UISelectors.updateBtn).style.display = 'inline';
    this.container.querySelector(this.UISelectors.deleteBtn).style.display = 'inline';
    this.container.querySelector(this.UISelectors.addBtn).style.display = 'none';
  }
  clearEditState() {
    this.clearInput();
    this.container.querySelector(this.UISelectors.updateBtn).style.display = 'none';
    this.container.querySelector(this.UISelectors.deleteBtn).style.display = 'none';
    this.container.querySelector(this.UISelectors.addBtn).style.display = 'inline';
  }
}

export default class DynamicInput {
  constructor(id, one = false) {
    this.container = document.querySelector(id);
    if (this.container !== null) {
      this.UICtrl = new UICtrl(id, one);
      this.ItemCtrl = new ItemCtrl();
      this.inputs = this.container.querySelectorAll('input');
      this.init();
    }
  }

  loadEventListeners() {
    //   disable Keypress for input fields
    this.inputs.forEach((each) => {
      each.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          e.preventDefault();
          return false;
        }
      });
    });

    const UISelectors = this.UICtrl.getSelectors();
    this.container.querySelector(UISelectors.addBtn).addEventListener('click', (e) => {
      this.itemAddSubmit(e);
    });
    this.container.querySelector(UISelectors.items).addEventListener('click', (e) => this.itemEditClick(e, this));
    this.container
      .querySelector(UISelectors.updateBtn)
      .addEventListener('click', (e) => this.itemUpdateSubmit(e, this));
    this.container
      .querySelector(UISelectors.deleteBtn)
      .addEventListener('click', (e) => this.itemDeleteSubmit(e, this));
  }
  async itemAddSubmit(e) {
    e.preventDefault();
    const input = this.UICtrl.getItemInput();
    if (input.title !== '' && input.content !== '') {
      const newItem = await this.ItemCtrl.addItem(input.title, input.content);
      this.UICtrl.addItem(newItem);
      this.UICtrl.clearInput();
    }
  }
  async itemEditClick(e) {
    e.preventDefault();
    if (e.target.classList.contains('edit-item')) {
      const listId = e.target.parentNode.parentNode.id;
      const listIdArr = listId.split('-');
      const itemToEdit = this.ItemCtrl.getItemById(parseInt(listIdArr[1]));
      this.ItemCtrl.setCurrentItem(itemToEdit);
      this.UICtrl.addItemToForm(itemToEdit);
    }
  }
  async itemUpdateSubmit(e) {
    e.preventDefault();
    const input = this.UICtrl.getItemInput();
    const updatedItem = await this.ItemCtrl.updateItem(input);
    this.UICtrl.updateListItem(updatedItem);
    this.UICtrl.clearEditState();
  }
  async itemDeleteSubmit(e) {
    e.preventDefault();
    const currentItem = this.ItemCtrl.getCurrentItem();
    await this.ItemCtrl.deleteItem(currentItem.id);
    this.UICtrl.deleteItem(currentItem.id);
    this.UICtrl.clearEditState();
  }
  async init() {
    await this.ItemCtrl.init();
    this.UICtrl.init(this.ItemCtrl.items);
    this.loadEventListeners();
  }
  getItems() {
    return this.ItemCtrl.getItems();
  }
}
