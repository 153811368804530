import Handlers from '../../js/Libraries/Handlers';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Standard from '../../js/Libraries/Standard';
import Dropdown from './Dropdown';
import AdminTextBox from './AdminTextBox';
import { validateSize, validateFormat } from '../../js/Libraries/Form';

export default class GallerieAdmin extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.box = new AdminTextBox();
    this.row = super.set('#all_images_container');
    this.addElement = super.set('.newGallerieImageColumn');
    this.addFile = super.set('#newGallerieImage');
    this.endpointRead = super.setEndpoint('gallerie_api/get_images');
    this.endpointCreate = super.setEndpoint('gallerie_api/upload_image');
    this.endpointDelete = super.setEndpoint('gallerie_api/delete_image');
    this.endpointUpdateTitle = super.setEndpoint('gallerie_api/update_title?id=');
    this.formDataName = 'image_file';
    this.init();
  }

  async init() {
    this.hl.toggle();
    await this.read();
    this.events();
    return this.hl.toggle();
  }

  async read() {
    const res = await this.http.get(this.endpointRead);
    console.log(res);
    if (res.status == 200) {
      const length = res.result.length;
      for (let i = 1; i <= length; i++) {
        let obj = res.result.find((x) => x.position == i);
        this.createNewElement(obj);
      }
      const dropdownElements = document.querySelectorAll('.dropdown-element');
      dropdownElements.forEach((each) => {
        new Dropdown(each, length);
      });
    } else {
      location.reload();
    }
  }

  createNewElement(data) {
    const el = document.createElement('div');
    el.classList = 'img_container col-md-6 col-lg-4';
    el.setAttribute('data-position', data.position);
    el.setAttribute('data-id', data.id);
    el.innerHTML = this.component(data.path, data.title, data.height);
    this.row.insertBefore(el, this.addElement);
  }
  component(path, text, height) {
    return `            
          <img src="${path}" alt="Bild in Gallerie von Silke Gerbershagen" data-text="${text}" style="height: ${+height}px ;" data-height ="${+height}"/>
          <div class="extra-admin">
            <div class="title-admin">
                extras <i class="fas fa-pencil-alt edit-title"></i>
            </div>
            <div class="dropdown-element">
              <button class="dropdown-btn">
                Position:
                <span class="choice">2</span
                ><i class="fas fa-caret-down"></i>
              </button>
              <ul></ul>
            </div>
            <p class="delete-btn">Löschen</p>
          </div>`;
  }

  async deleteImage(e) {
    if (e.target.classList == 'delete-btn') {
      const currentColumn = e.target.parentNode.parentNode;
      const currentId = currentColumn.dataset.id;

      const resHl = await this.hl.message('Möchtest du das Bild wirklich löschen?', (confirm = true));
      if (!resHl) {
        return;
      }
      this.hl.toggle();
      const res = await this.http.delete(this.endpointDelete + '?id=' + currentId);
      console.log(res);
      this.hl.wait(1000);
      location.reload();
    }
  }

  async submitAdd() {
    const file = this.addFile.files[0];

    if (file === undefined) {
      return;
    }

    this.hl.toggle();
    if (!validateSize(file.size, 5000000)) {
      this.hl.message('Bitte wähle ein Bild mit weniger wie 5 MB.');
      return this.hl.toggle();
    }
    if (!validateFormat(file.type)) {
      this.hl.message('Bitte wähle JPEG,JPG oder PNG als ein gültiges Bildformat.');
      return this.hl.toggle();
    }
    // sent to backend
    var data = new FormData();
    data.append(this.formDataName, file);

    const res = await this.http.postForm(this.endpointCreate, data);
    if (res.status === 403) {
      this.hl.message(`Du darfst maximal 5 MB Bilder für dein Portfolio hochladen.`);
      return this.hl.toggle();
    } else if (res.status !== 201) {
      this.hl.message('Es gibt zurzeit leider Probleme mit unserem Server. Versuche es später noch einmal.');
      return this.hl.toggle();
    }
    location.reload();
  }

  async editTitle(obj) {
    const root = obj.parentNode.parentNode.parentNode;
    const text = root.querySelector('img').dataset.text;
    const height = root.querySelector('img').dataset.height;
    const editContent = await this.box.toggle(text, height);

    if (editContent === false) {
      return;
    } else {
      const text = editContent[0];
      const height = parseInt(editContent[1]);
      if (height >= 200 && height <= 500) {
        const reqBody = {
          title: text,
          height: String(height),
        };
        const res = await this.http.put(this.endpointUpdateTitle + root.dataset.id, reqBody);
        console.log(res);
        this.hl.toggle();
        await this.hl.wait();
        location.reload();
      }

      return;
    }
  }

  events() {
    this.row.addEventListener('click', (e) => this.deleteImage(e));
    this.addFile.addEventListener('change', () => this.submitAdd());
    super.set('.edit-title', true).forEach((each) => {
      each.addEventListener('click', () => {
        this.editTitle(each);
      });
    });
  }
}
